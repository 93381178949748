import React from "react";

import { api_url } from "../commons/api_url";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export function fetchAdminReports(semesterId) {
  const url = `${api_url}reports/admin-report/${semesterId}`;
  return (dispatch) => {
    dispatch({ type: "LOADING_REPORTS" });
    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => dispatch({ type: "SET_REPORTS", payload: data }))
      .catch((error) => {
        MySwal.fire({
          title: "Hubo un error.",
          icon: "error",
          confirmButtonText: "continuar",
        });
      });
  };
}
