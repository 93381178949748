export default [
  {
    id: 1,
    name: "Mayagüez",
  },
  {
    id: 2,
    name: "Aguada",
  },
  {
    id: 3,
    name: "Carolina",
  },
  {
    id: 4,
    name: "Moca",
  },
];
