export default [
  "2018-19: 1",
  "2018-19: 2",
  "2019-20: 1",
  "2019-20: 2",
  "2020-21: 1",
  "2020-21: 2",
  "2021-22: 1",
  "2021-22: 2",
  "2022-23: 1",
  "2022-23: 2",
  "2023-24: 1",
  "2023-24: 2",
  "2024-25: 1",
  "2024-25: 2",
];
