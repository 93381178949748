import { api_url } from "../commons/api_url";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export function fetchFacultyUsers() {
  return (dispatch) => {
    dispatch({ type: "LOADING_FACULTY" });
    return fetch(`${api_url}users`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((response) => {
        return response.json();
      })
      .then((responseJSON) => {
        dispatch({ type: "FETCH_FACULTY_USERS", payload: responseJSON });
      })
      .catch((error) => {
        MySwal.fire({
          title: "Hubo un error.",
          icon: "error",
          confirmButtonText: "continuar",
        });
      });
  };
}

export function fetchFacultyUser(role, id) {
  // let apiEndPoint = role === "Teacher" ? "teachers" : "users";
  let apiEndPoint = "users";
  return (dispatch) => {
    dispatch({ type: "LOADING_FACULTY" });
    return fetch(`${api_url}${apiEndPoint}/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((response) => {
        return response.json();
      })
      .then((responseJSON) => {
        dispatch({ type: "FETCH_FACULTY_USER", payload: responseJSON });
      })
      .catch((error) => {
        MySwal.fire({
          title: "Hubo un error.",
          icon: "error",
          confirmButtonText: "continuar",
        });
      });
  };
}
