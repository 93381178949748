import React from "react";

import Event from "./Event";

export default function EventsList(props) {
  const generateEvents = () => {
    return props.events.map((event) => {
      return (
        <Event
          event={event}
          currentEvent={props.currentEvent}
          setCurrentEvent={props.setCurrentEvent}
        />
      );
    });
  };
  return <ul className="events-list events-scrollable">{generateEvents()}</ul>;
}
